import React, { useEffect, useState } from 'react'
import fs from 'fs';
import axios from 'axios';
import { CursorArrowRippleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import { ShimmerCategoryItem } from 'react-shimmer-effects';
const Genres = () => {

    const [colors, setColors] = useState(null);
    const [file, setFile] = useState(null);
    const [allApps, setAllApps] = useState(null);

    const [isLoad, setIsLoad] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            if (isLoad) {
                try {
                    // Fetch the genres data
                    const resp = await axios.get('https://node.aryzap.com/api/genres');
                    
                    // Array to store promises for axios requests
                    const promises = resp.data.map(async (item) => {
                        try {
                            // Fetch count for each genre
                            const response = await axios.get(`https://node.aryzap.com/api/series/getCountbyGenre/${item._id}`);
                            item.count = response.data.count; // Add count to the genre item
                        } catch (error) {
                            console.error(`Error fetching count for item with id ${item._id}:`, error);
                            item.count = 'N/A'; // Default count in case of an error
                        }
                    });

                    // Wait for all promises to resolve
                    await Promise.all(promises);

                    // Update the state with the updated data
                    setAllApps(resp.data);
                    console.log(resp.data);
                } catch (error) {
                    console.error("Error fetching genres:", error);
                } finally {
                    // Ensure the isLoad state is set to false
                    setIsLoad(false);
                }
            }
        };

        fetchData();
    }, [isLoad]);




    return (

        <>
            <div className="text-2xl font-bold pb-2 mb-5  border-b border-b-gray-500 ">
                Genres
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                <a href="/genres/create">
                    <div className={"rounded-md hover:bg-gray-950 bg-blue-950 animate-pulse"}>

                        <div className="p-6 ">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">


                                    <CursorArrowRippleIcon width={30} color='lightgreen' />

                                    <h2 className="text-sm font-bold mt-2">
                                        Add an Genres +
                                    </h2>
                                    <p className='text-xs font-light w-52'>
                                        Drama. Stories composed in verse or prose, usually for theatrical performance, where conflicts and emotion are expressed through dialogue and action.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
                {allApps ? allApps.map((app, index) => {
                    return (
                        <a href={`/genres/${app._id}`}>
                            <div key={index} className={"rounded-md bg-gray-950"}>

                                <div className="p-6 ">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">


                                            <ArrowRightCircleIcon width={24} />
                                            <h2 className="text-sm font-bold mt-2">
                                                {app.title}
                                            </h2>
                                            <p className='text-xs font-light text-clip w-56'>
                                                {app.description}
                                            </p>
                                        </div>
                                    </div>
                            
                                </div>
                                {/* <div className='flex-1 bg-red-700 text-center text-sm my-2'>Document Count: {app.count}  </div> */}
                                <div className='flex-1 bg-red-700 text-center text-sm'>Document Count: {app.count} <a href={`${'genres/positioning/' + app._id}`}> View All </a> </div>
                       
                                </div>
                        </a>
                    )
                }) :

                    <>

                        <div className="bg-gray-800 rounded-md pl-5 pt-5">

                            <ShimmerCategoryItem title={false} />
                        </div>
                        <div className="bg-gray-800 rounded-md pl-5 pt-5">

                            <ShimmerCategoryItem title={false} />
                        </div>
                        <div className="bg-gray-800 rounded-md pl-5 pt-5">

                            <ShimmerCategoryItem title={false} />
                        </div>
                        <div className="bg-gray-800 rounded-md pl-5 pt-5">

                            <ShimmerCategoryItem title={false} />
                        </div>
                        <div className="bg-gray-800 rounded-md pl-5 pt-5">

                            <ShimmerCategoryItem title={false} />
                        </div>
                        <div className="bg-gray-800 rounded-md pl-5 pt-5">

                            <ShimmerCategoryItem title={false} />
                        </div>
                        <div className="bg-gray-800 rounded-md pl-5 pt-5">

                            <ShimmerCategoryItem title={false} />
                        </div>


                    </>}


            </div>


        </>
    )
}

export default Genres