import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

const PromotionalEdit = (props) => {
    const [apps, setApps] = useState(null);
    const [bannerDetails, setBannerDetails] = useState(null);
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [genreTitle, setGenreTitle] = useState('');
    const [genreDesc, setGenreDesc] = useState('');
    const [appId, setAppId] = useState('');
    const [imagePath, setImgPath] = useState(null);
    const bannerId = useParams(props);
    useEffect(() => {
        // Fetch all apps
        axios.get('https://node.aryzap.com/api/apps/')
            .then((response) => setApps(response.data))
            .catch((error) => alert(error.message));

        // Fetch banner details for editing
        if (bannerId.id) {
            axios.get(`https://node.aryzap.com/api/pb/${bannerId.id}`)
                .then((response) => {
                    const data = response.data.promotionalBanner;
                    setBannerDetails(data);
                    setGenreTitle(data.title);
                    setGenreDesc(data.action);
                    setAppId(data.appId);
                    setImgPath(data.image);
                })
                .catch((error) => alert(`Error fetching banner details: ${error.message}`));
        }
    }, [bannerId.id]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let updatedImagePath = imagePath;

        if (file) {
            // Upload the new image if a file is selected
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post('https://node.aryzap.com/api/media/pbupload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                updatedImagePath = response.data.imagePath;
            } catch (error) {
                alert('Error uploading image: ' + error.message);
                setIsLoading(false);
                return;
            }
        }

        // Update promotional banner
        try {
            const response = await axios.put(`https://node.aryzap.com/api/pb/${bannerId.id}`, {
                title: genreTitle,
                action: genreDesc,
                image: updatedImagePath,
                appId: appId,
            });
            if (response.status === 200) {
                alert('Promotional banner updated successfully!');
            }
        } catch (error) {
            alert('Error updating promotional banner: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="text-2xl font-bold pb-2 mb-5 border-b border-b-gray-500">
                Promotional Banner <span className='font-extrabold'>{'>'}</span> Edit Promotional Banner
            </div>

            <section className="bg-white dark:bg-gray-600">
                <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
                    <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Edit Promotional Banner</h2>
                    {bannerDetails ? (
                        <form onSubmit={handleSubmit} method="POST">
                            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                                <div className="sm:col-span-2">
                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Promotional Banner Name
                                    </label>
                                    <input
                                        value={genreTitle}
                                        onChange={(e) => setGenreTitle(e.target.value)}
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        placeholder="Type banner name"
                                        required
                                    />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="file_input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Promotional Banner Image
                                    </label>
                                    <input
                                        onChange={handleImageChange}
                                        className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-600"
                                        id="file_input"
                                        accept="image/*"
                                        type="file"
                                    />
                                    {imagePath && <img src={'https://node.aryzap.com/public/promotionalBanner/' + imagePath} alt="Current banner" className="mt-2 w-40 h-20" />}
                                    {isLoading && <InfinitySpin width="200" color="#FFFFFF" />}
                                </div>
                                <div className="w-full">
                                    <label htmlFor="app_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        App ID
                                    </label>
                                    <select
                                        value={appId}
                                        onChange={(e) => setAppId(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                    >
                                        {apps &&
                                            apps.map((app) => (
                                                <option key={app._id} value={app._id}>
                                                    {app.title}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Action
                                    </label>
                                    <textarea
                                        value={genreDesc}
                                        onChange={(e) => setGenreDesc(e.target.value)}
                                        id="description"
                                        rows="8"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                                        placeholder="Action description here"
                                    ></textarea>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="inline-flex items-center px-5 py-2.5 mt-4 bg-gray-950 hover:bg-gray-800 text-sm font-medium text-white rounded-lg"
                            >
                                Update Promotional Banner
                            </button>
                        </form>
                    ) : (
                        <div>Loading banner details...</div>
                    )}
                </div>
            </section>
        </>
    );
};

export default PromotionalEdit;
