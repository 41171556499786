import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import { CursorArrowRippleIcon } from '@heroicons/react/24/outline';

const EditAd = (props) => {
    const navigate = useNavigate(); // To redirect after submission
    const [adData, setAdData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [size, setSize] = useState('');
    const [tagUrl, setTagUrl] = useState('');
    const [comments, setComments] = useState('');
    const [error, setError] = useState(null);
    const params = useParams(props); // Fetch the ad ID from the URL

    // Fetch ad data based on the ID
    useEffect(() => {
        if (params.id) {
            axios.get(`https://node.aryzap.com/api/dads/${params.id}`)
                .then((response) => {
                    const data = response.data;
                    setAdData(data);
                    setTitle(data.title || '');
                    setSize(data.size || '');
                    setTagUrl(data.adUnit || '');
                    setComments(data.comments || '');
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setError('Failed to fetch ad data.');
                    setIsLoading(false);
                });
        }
    }, [params.id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Prepare data for update
        const updatedAd = {
            title: title,
            size: size,
            adUnit: tagUrl,
            comments: comments,
        };

        // Send update request
        axios.put(`https://node.aryzap.com/api/dads/${params.id}`, updatedAd)
            .then(() => {
                alert('Ad updated successfully!');
                navigate('/dads/' + params.id); // Redirect to ads list
            })
            .catch((err) => {
                console.error(err);
                setError('Failed to update ad.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <InfinitySpin width="200" color="#4A90E2" />
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500 text-center">{error}</div>;
    }

    return (
        <div className="rounded-md hover:bg-gray-950 bg-blue-950 p-6 ">
            <div className="flex items-center mb-6">
                <div className="flex-shrink-0">
                    <CursorArrowRippleIcon width={30} color="orange" />
                </div>
                <h2 className="text-2xl font-bold ml-4 text-white">Edit Display Ad</h2>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-200">Title</label>
                    <input
                        type="text"
                        className="bg-gray-800 border border-gray-600 text-gray-100 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-200">Size</label>
                    <select
                        className="bg-gray-800 border border-gray-600 text-gray-100 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                        required
                    >
                        <option selected disabled>
                            Select Size
                        </option>
                        <option value={'mediumRectangle'}> MEDIUM RECTANGLE </option>
                        <option value={'leaderBoard'}> LEADER BOARD </option>
                        <option value={'largeBanner'}> LARGE BANNER </option>
                        <option value={'smallBanner'}> SMALL BANNER </option>
                        <option value={'catFish'}> CATFISH </option>
                        <option value={'smartBanner'}> SMART BANNER </option>
                        <option value={'autoResponsive'}> AUTO RESPONSIVE </option>
                    </select>
                    {/* <input
                        type="text"
                        className="bg-gray-800 border border-gray-600 text-gray-100 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                        required
                    /> */}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-200">Ads Tag URL / AD-Uint</label>
                    <input
                        type="text"
                        className="bg-gray-800 border border-gray-600 text-gray-100 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        value={tagUrl}
                        onChange={(e) => setTagUrl(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-200">Comments</label>
                    <textarea
                        className="bg-gray-800 border border-gray-600 text-gray-100 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        rows="4"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-orange-600 text-white py-3 px-4 rounded-md hover:bg-orange-700 transition"
                >
                    Update Ad
                </button>
            </form>
        </div>
    );
};

export default EditAd;
