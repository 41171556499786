import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const AdsEdit = (props) => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [adsTitle, setAdsTitle] = useState('');
    const [adsType, setAdsType] = useState('');
    const [adsTag, setAdsTag] = useState('');
    const params = useParams(props);
    // Fetch ad details
    useEffect(() => {
        const fetchAdDetails = async () => {
            try {
                const response = await axios.get(`https://node.aryzap.com/api/ads/${params.id}`);
                const { title, type, tag } = response.data;
                setAdsTitle(title);
                setAdsType(type);
                setAdsTag(tag);
            } catch (error) {
                console.error('Error fetching ad details:', error);
                toast.error('Failed to fetch ad details');
            }
        };

        if (params.id) {
            fetchAdDetails();
        }
    }, [params]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axios.put(`https://node.aryzap.com/api/ads/${params.id}`, {
                title: adsTitle,
                type: adsType,
                tag: adsTag,
            });

            if (response.status === 200) {
                toast.success('Ad updated successfully!');
            } else {
                toast.error('Failed to update ad');
            }
        } catch (error) {
            console.error('Error updating ad:', error);
            toast.error('An error occurred while updating the ad');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="text-2xl font-bold pb-2 mb-5 border-b border-b-gray-500">
                Ads <span className="font-extrabold">{'>'}</span> Edit Ad
            </div>

            <Toaster position="top-center" reverseOrder={false} />

            <section className="bg-white dark:bg-gray-600">
                <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
                    <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Edit Ad</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                            <div className="sm:col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Ads Title
                                </label>
                                <input
                                    value={adsTitle}
                                    onChange={(e) => setAdsTitle(e.target.value)}
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                    placeholder="Ad title"
                                    required
                                />
                            </div>
                            <div className="w-full sm:col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Ads Type
                                </label>
                                <input
                                    value={adsType}
                                    onChange={(e) => setAdsType(e.target.value)}
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                    placeholder="Ad type"
                                    required
                                />
                            </div>
                            <div className="sm:col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Ads Tag URL/Ad Unit
                                </label>
                                <textarea
                                    value={adsTag}
                                    onChange={(e) => setAdsTag(e.target.value)}
                                    rows="8"
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                                    placeholder="Ad tag URL or unit"
                                    required
                                />
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="bg-black inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-white bg-primary-700 rounded-lg hover:bg-primary-800"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Saving...' : 'Update Ad'}
                        </button>
                    </form>
                </div>
            </section>
        </>
    );
};

export default AdsEdit;
