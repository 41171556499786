import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

const GenresEdit = (props) => {
    const [apps, setApps] = useState(null);
    const [appsLoad, setAppsLoad] = useState(true);
    const [file, setFile] = useState(null);
    const [imagePath, setImgPath] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [genreTitle, setGenreTitle] = useState('');
    const [genreDesc, setGenreDesc] = useState('');
    const [appId, setAppId] = useState('');
    const [existingGenre, setExistingGenre] = useState(null);
    const params = useParams(props);
    useEffect(() => {
        if (appsLoad) {
            // Fetch apps
            axios.get('https://node.aryzap.com/api/apps/')
                .then(response => {
                    setApps(response.data);
                    setAppsLoad(false);
                })
                .catch(error => alert(error.message));
        }
    }, [appsLoad]);

    useEffect(() => {
        // Fetch existing genre details
        axios.get(`https://node.aryzap.com/api/genres/${params.id}`)
            .then(response => {
                setExistingGenre(response.data);
                setGenreTitle(response.data.title);
                setGenreDesc(response.data.description);
                setImgPath(response.data.image);
                setAppId(response.data.appId);
            })
            .catch(error => alert(error.message));
    }, [params.id]);

    const handleImageChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        setFile(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let uploadedImagePath = imagePath;

        // If a new image is selected, upload it
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post('https://node.aryzap.com/api/media/upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                uploadedImagePath = response.data.imagePath;
            } catch (error) {
                console.error('Error uploading image:', error);
                setIsLoading(false);
                return;
            }
        }

        // Update genre
        try {
            await axios.put(`https://node.aryzap.com/api/genres/${params.id}`, {
                title: genreTitle,
                description: genreDesc,
                image: uploadedImagePath,
                appId: appId,
            });
            alert('Genre updated successfully!');
        } catch (error) {
            console.error('Error updating genre:', error);
            alert('Failed to update genre.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <div className="text-2xl font-bold pb-2 mb-5 border-b border-b-gray-500">
                Genres <span className="font-extrabold">{'>'}</span> Edit Genre
            </div>

            <section className="bg-white dark:bg-gray-600">
                <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
                    <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Edit Genre</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                            <div className="sm:col-span-2">
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Genre Name
                                </label>
                                <input
                                    value={genreTitle}
                                    onChange={(e) => setGenreTitle(e.target.value)}
                                    type="text"
                                    id="name"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                    placeholder="Type genre name"
                                    required
                                />
                            </div>

                            <div className="w-full">
                                <label htmlFor="file_input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Genre Image
                                </label>
                                <input
                                    onChange={handleImageChange}
                                    className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-600 dark:text-gray-400 focus:outline-none dark:bg-gray-700"
                                    id="file_input"
                                    accept="image/*"
                                    type="file"
                                />
                                {imagePath && <p className="text-sm text-gray-500">Current Image: <img src={"https://node.aryzap.com/public/" + imagePath} /></p>}
                                {isLoading && <InfinitySpin width="200" color="#FFFFFF" />}
                            </div>

                            <div className="w-full">
                                <label htmlFor="appId" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    App ID
                                </label>
                                <select
                                    value={appId}
                                    onChange={(e) => setAppId(e.target.value)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                                >
                                    {apps && apps.map((app) => (
                                        <option key={app._id} value={app._id}>
                                            {app.title}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Description
                                </label>
                                <textarea
                                    value={genreDesc}
                                    onChange={(e) => setGenreDesc(e.target.value)}
                                    id="description"
                                    rows="8"
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
                                    placeholder="Genre description here"
                                ></textarea>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="inline-flex items-center px-5 py-2.5 mt-4 text-sm font-medium text-white bg-gray-950 hover:bg-gray-800 rounded-lg focus:ring-4 focus:ring-primary-200"
                        >
                            Update Genre
                        </button>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default GenresEdit;
