import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TrashIcon, PlusIcon, PauseIcon, PlayIcon } from '@heroicons/react/24/outline';
import { ShimmerTable } from 'react-shimmer-effects';

const CronJobs = () => {
    const [cronJobs, setCronJobs] = useState(null);
    const [seriesTitles, setSeriesTitles] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    // Fetch all cron jobs from the backend
    useEffect(() => {
        const fetchCronJobs = async () => {
            try {
                const response = await axios.get('https://node.aryzap.com/api/cjobs');
                setCronJobs(response.data);

                // Fetch series titles for each seriesId in the cron jobs
                const seriesIds = response.data.map(job => job.seriesId);
                await fetchSeriesTitles(seriesIds);
            } catch (error) {
                console.error("Error fetching cron jobs:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCronJobs();
    }, []);

    // Function to fetch series titles
    const fetchSeriesTitles = async (seriesIds) => {
        const titles = {};
        for (const id of seriesIds) {
            try {
                const response = await axios.get(`https://node.aryzap.com/api/series/${id}`);
                titles[id] = response.data.title;
            } catch (error) {
                console.error(`Error fetching series title for ID ${id}:`, error);
            }
        }
        setSeriesTitles(titles);
    };

    // Format date
    const formatDate = (dateString) => {
        if (!dateString) return 'Never';
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            timeZone: 'Asia/Karachi'
        };
        return new Date(dateString).toLocaleString('en-US', options);
    };

    // Delete a cron job by ID
    const deleteCronJob = async (id) => {
        try {
            await axios.delete(`https://node.aryzap.com/api/cjobs/delete/${id}`);
            setCronJobs(cronJobs.filter(job => job._id !== id));
        } catch (error) {
            console.error(`Error deleting cron job with ID ${id}:`, error);
        }
    };

    // Stop a cron job by ID
    const stopCronJob = async (id) => {
        try {
            await axios.post(`https://node.aryzap.com/api/cjobs/stop/${id}`);
            setCronJobs(cronJobs.map(job => job._id === id ? { ...job, isRunning: false } : job));
        } catch (error) {
            console.error(`Error stopping cron job with ID ${id}:`, error);
        }
    };

    // Start a cron job by ID
    const startCronJob = async (id) => {
        try {
            await axios.post(`https://node.aryzap.com/api/cjobs/start/${id}`);
            setCronJobs(cronJobs.map(job => job._id === id ? { ...job, isRunning: true } : job));
        } catch (error) {
            console.error(`Error starting cron job with ID ${id}:`, error);
        }
    };

    return (
        <div className="p-4">
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-2xl font-bold">Cron Jobs</h1>
                <button
                    className="bg-red-600 hover:bg-red-500 text-white px-4 py-2 rounded-md flex items-center"
                    onClick={() => navigate('/cron/create')}
                >
                    <PlusIcon className="w-5 h-5 mr-2" />
                    Create Cron Job
                </button>
            </div>

            {isLoading ? (
                <ShimmerTable row={5} col={5} />
            ) : (
                <div className="overflow-x-auto">
                    <table className="table-auto border-collapse border border-gray-700 w-full text-sm text-left text-gray-400">
                        <thead className="bg-gray-800 text-xs uppercase text-gray-400">
                            <tr>
                                <th className="border border-gray-700 px-4 py-2">Series ID</th>
                                <th className="border border-gray-700 px-4 py-2">Series Name</th>
                                <th className="border border-gray-700 px-4 py-2">Schedule</th>
                                <th className="border border-gray-700 px-4 py-2">Last Run</th>
                                <th className="border border-gray-700 px-4 py-2">Status</th>
                                <th className="border border-gray-700 px-4 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cronJobs.map((job, index) => (
                                <tr key={index} className="bg-gray-900 hover:bg-gray-800">
                                    <td className="border border-gray-700 px-4 py-2">{job.seriesId}</td>
                                    <td className="border border-gray-700 px-4 py-2">{seriesTitles[job.seriesId] || 'Loading...'}</td>
                                    <td className="border border-gray-700 px-4 py-2">{job.schedule}</td>
                                    <td className="border border-gray-700 px-4 py-2">{formatDate(job.lastRun)}</td>
                                    <td className="border border-gray-700 px-4 py-2">{job.isRunning ? 'Running' : 'Stopped'}</td>
                                    <td className="border border-gray-700 px-4 py-2 text-center">
                                        {job.isRunning ? (
                                            <button
                                                className="bg-yellow-600 hover:bg-yellow-500 text-white px-3 py-1 rounded-md mr-2"
                                                onClick={() => stopCronJob(job._id)}
                                            >
                                                <PauseIcon className="inline-block w-4 h-4 mr-1" />
                                                Stop
                                            </button>
                                        ) : (
                                            <button
                                                className="bg-green-600 hover:bg-green-500 text-white px-3 py-1 rounded-md mr-2"
                                                onClick={() => startCronJob(job._id)}
                                            >
                                                <PlayIcon className="inline-block w-4 h-4 mr-1" />
                                                Start
                                            </button>
                                        )}
                                        <button
                                            className="bg-red-600 hover:bg-red-500 text-white px-3 py-1 rounded-md"
                                            onClick={() => deleteCronJob(job._id)}
                                        >
                                            <TrashIcon className="inline-block w-4 h-4 mr-1" />
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default CronJobs;
