import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

const AgesEdit = (props) => {
    const [apps, setApps] = useState(null);
    const [ageRating, setAgeRating] = useState(null);
    const [file, setFile] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [genreTitle, setGenreTitle] = useState('');
    const [genreDesc, setGenreDesc] = useState('');
    const [appId, setAppId] = useState('');
    const params = useParams(props);
    const ageId = params.id; // Assuming you're using React Router and `id` is a URL parameter.

    useEffect(() => {
        // Fetch available apps
        axios.get('https://node.aryzap.com/api/apps/')
            .then((response) => setApps(response.data))
            .catch((error) => alert(error.message));

        // Fetch age rating details
        axios.get(`https://node.aryzap.com/api/ageratings/${ageId}`)
            .then((response) => {
                const data = response.data;
                setAgeRating(data);
                setGenreTitle(data.title);
                setGenreDesc(data.description);
                setAppId(data.appId);
                setImagePath(response.data.image);
            })
            .catch((error) => alert(error.message));
    }, [ageId]);

    const handleImageChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        setFile(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let imagePath = ageRating?.image;

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post('https://node.aryzap.com/api/media/upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                imagePath = response.data.imagePath;
            } catch (error) {
                console.error('Image upload failed:', error);
                setIsLoading(false);
                return;
            }
        }

        // Update age rating details
        axios.put(`https://node.aryzap.com/api/ageratings/${ageId}`, {
            title: genreTitle,
            description: genreDesc,
            image: imagePath,
            appId,
        })
            .then((response) => {
                if (response.status === 200) {
                    alert('Age rating updated successfully!');
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Update failed:', error);
                alert('Error updating age rating.');
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className="text-2xl font-bold pb-2 mb-5 border-b border-b-gray-500 ">
                Edit Age Rating
            </div>

            <section className="bg-gray-600">
                <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
                    <h2 className="mb-4 text-xl font-bold text-white">Edit Age Rating</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                            <div className="sm:col-span-2">
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-white">Age Name</label>
                                <input
                                    value={genreTitle}
                                    onChange={(e) => setGenreTitle(e.target.value)}
                                    type="text"
                                    id="name"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Enter age name"
                                    required
                                />
                            </div>
                            <div className="w-full">
                                <label htmlFor="file_input" className="block mb-2 text-sm font-medium text-white">Age Rating Image</label>
                                <input
                                    onChange={handleImageChange}
                                    className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-600"
                                    id="file_input"
                                    accept="image/*"
                                    type="file"
                                />
                                <p className="mt-1 text-sm text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px).</p>
                                {imagePath && <img src={"https://node.aryzap.com/public/" + imagePath} alt="Uploaded" className="mt-4 max-w-xs" />}
                                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">SVG, PNG, JPG or GIF (MAX. 800x400px).</p>
                                {isLoading && <InfinitySpin width='200' color="#FFFFFF" />}
                                {isLoading && <InfinitySpin width='200' color="#FFFFFF" />}
                            </div>
                            <div className="w-full">
                                <label htmlFor="appId" className="block mb-2 text-sm font-medium text-white">App ID</label>
                                <select
                                    value={appId}
                                    onChange={(e) => setAppId(e.target.value)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                    {apps && apps.map((app) => (
                                        <option key={app._id} value={app._id}>{app.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="description" className="block mb-2 text-sm font-medium text-white">Description</label>
                                <textarea
                                    value={genreDesc}
                                    onChange={(e) => setGenreDesc(e.target.value)}
                                    id="description"
                                    rows="8"
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                    placeholder="Enter description here">
                                </textarea>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="inline-flex items-center px-5 py-2.5 mt-4 bg-gray-950 hover:bg-gray-800 text-sm font-medium text-white rounded-lg focus:ring-4 focus:ring-primary-200">
                            Update Age Rating
                        </button>
                    </form>
                </div>
            </section>
        </>
    );
};

export default AgesEdit;
