import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';

const CreateCronJob = () => {
    const [seriesOptions, setSeriesOptions] = useState([]);
    const [selectedSeries, setSelectedSeries] = useState(null);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Predefined schedule options
    const scheduleOptions = [
        { value: '0 17-23 * * *', label: 'Every Hour Between 5:00 PM and 11:00 PM Daily' },
        { value: '*/1 * * * *', label: 'Every minute' },
        { value: '*/5 * * * *', label: 'Every 5 minutes' },
        { value: '0 * * * *', label: 'Every hour' },
        { value: '0 8 * * *', label: 'Every day at 8 AM' },
        { value: '0 9 * * 1', label: 'Every Monday at 9 AM' },
        { value: '0 0 * * 0', label: 'Every Sunday at midnight' },
    ];

    // Fetch all series from the backend
    useEffect(() => {
        const fetchSeries = async () => {
            try {
                const response = await axios.get('https://node.aryzap.com/api/series');
                const options = response.data.series.map(series => ({
                    value: series._id,
                    label: series.title
                }));
                setSeriesOptions(options);
            } catch (error) {
                console.error("Error fetching series:", error);
            }
        };

        fetchSeries();
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedSeries || !selectedSchedule) {
            setError('Both Series and Schedule are required.');
            return;
        }

        try {
            const response = await axios.post('https://node.aryzap.com/api/cjobs/create', {
                seriesId: selectedSeries.value,
                schedule: selectedSchedule.value,
            });

            setMessage("Thanks you for creating a new job");
            setError('');
            setSelectedSeries(null);
            setSelectedSchedule(null);
        } catch (err) {
            setError('Failed to create cron job. Please try again.');
            setMessage('');
        }
    };

    return (
        <div className="max-w-lg mx-auto mt-10 bg-gray-800 p-6 rounded-md shadow-md">
            <h1 className="text-2xl font-bold text-white mb-6">Create Cron Job</h1>

            {message && <p className="bg-green-500 text-white p-2 rounded mb-4">{message}</p>}
            {error && <p className="bg-red-500 text-white p-2 rounded mb-4">{error}</p>}

            <form onSubmit={handleSubmit}>
                {/* Select Series Dropdown */}
                <div className="mb-4">
                    <label htmlFor="series" className="block text-white mb-2">Select Series</label>
                    <Select
                        id="series"
                        options={seriesOptions}
                        value={selectedSeries}
                        onChange={setSelectedSeries}
                        placeholder="Search and select a series..."
                        className="text-black"
                    />
                </div>

                {/* Select Schedule Dropdown */}
                <div className="mb-4">
                    <label htmlFor="schedule" className="block text-white mb-2">Select Schedule</label>
                    <Select
                        id="schedule"
                        options={scheduleOptions}
                        value={selectedSchedule}
                        onChange={setSelectedSchedule}
                        placeholder="Select a schedule..."
                        className="text-black"
                    />
                </div>

                <button
                    type="submit"
                    className="bg-blue-600 hover:bg-blue-500 text-white px-4 py-2 rounded"
                >
                    Create Cron Job
                </button>
            </form>
        </div>
    );
};

export default CreateCronJob;
